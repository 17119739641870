// RxJS
// import 'rxjs';
import './assets/styles/css/styles.css';
import './assets/styles/scss/lingueopro-styles.scss';
import './assets/styles/scss/teacher-styles.scss';
import './assets/styles/scss/backoffice-styles.scss';
import '../node_modules/materialize-css/dist/css/materialize.min.css';
// import '../node_modules/jquery/dist/jquery.min.js';
import '../node_modules/materialize-css/dist/js/materialize.min.js';
// Angular
// import '@angular/platform-browser';
// import '@angular/platform-browser-dynamic';
// import '@angular/core';
// import '@angular/common';
// import '@angular/http';
// import '@angular/router';
// import './app/material.module.ts';
